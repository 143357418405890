import React, { useState } from 'react'

const Player = () => {

	return (
		
		<div className="contact">
		<h2 class="contact-title">Contact Me</h2>
		<form action="https://formsubmit.co/romelolopez@zoho.com" method="POST">
			<div class="contact-form">
				<div class="inputs">
					<label>Name:</label>
					<input type="text" name="name" class="boxes" required />
				</div>
				<div class="inputs">
					<label>Email:</label>
					<input type="email" name="email" class="boxes" required/ >
				</div>
				<div class="inputs">
					<label>Message:</label>
					<textarea type="text" class="boxes" name="message" rows="3" cols="36" placeholder="Enter Message..."></textarea>
				</div>
				<button className="form-btn"type="submit">Send</button>
			</div>
		</form>
		</div>
		
	);
}

export default Player;