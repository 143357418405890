import React, { useEffect, useState } from 'react'


const Team = () => {
		
		useEffect(() => {
			
		},)
    

  return (
    <div className="community">
		<h1 className="community-h1">Community</h1>
		<div className='img-container'>
			<img src={'/images/IMG_8657.jpg'}/>
			<img src={'/images/IMG_8680.jpg'}/>
			<img src={'/images/IMG_3869.jpg'}/>
			<img src={'/images/IMG_3874.jpg'}/>
		</div>
        
    </div>
      
    
  );
}

export default Team;