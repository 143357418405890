import React, { useEffect, useState } from 'react'
import Nav from "./Nav"
import Player from "./Player"
import Team from "./Team"
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom"
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs';


const Home = () => {

    useEffect(() => {

		//re-render because when count changes the API link changes and we need the new info
    }, )



  return (
    
    <div className="home">
 
		<Router>
		<Nav />
		<Switch>
			<Route exact path="/"><Redirect to="/Home" /></Route>
			<Route path="/Home">
			
				
			<div className="first-page">
			<h2 className="home-quote">"The difference bewteen success and failure is support"</h2>
				<h1 className="home-h1">Mission</h1>
				<p className="home-p">Making A Pathway (M.A.P.) is a grassroots charitable organization. M.A.P.'s mission is to 
					create and support charitable programs 
					for safety mindedness, personal, educational and professional development for youth and adults.</p>
			</div>
			<div className="second-page">
				<h1 className="home-h1">Goal</h1>
				<p className="home-p">MAP, Inc’s goal is to provide access to participants to use the activities we offer to 
				build life skills, for social, personal and financial stability. 
				MAP wants to help people with limited means, position themselves for good lives.</p>
			</div>

			<div className="third-page">
				<h1 className="home-h1">Community</h1>
				<p className="home-p">We believe in volunteerism to build society. Presently, our major activities 
				are career day presentations, 
				a team in the Bronx for the American Foundation for Suicide Prevention's Out of the Darkness Walk.</p>
			</div>
			
				
			</Route>
			<Route path="/Team" component={Team}></Route>
			<Route path="/Player" component={Player}></Route>
			
		</Switch>
		</Router>
		
    </div>
      
    
  );
}

export default Home;